<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.maxResSameStartForm"
    />
    <h3 class="pb-2" v-if="!addingRule">
      Maximum Reservations With Same Start Date
      <hr />
    </h3>
    <button
      class="btn btn-primary mb-3"
      v-if="!addingRule"
      @click="addingRule = true"
    >
      Add New Rule
      <i class="fa fa-plus-circle" />
    </button>
    <div v-if="addingRule" class="card">
      <div class="card-header">
        <div class="card-title mb-0">
          Maximum Reservations With Same Start Date
        </div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="maxResSameStartForm">
          <form @submit.prevent="onSubmit">
            <AdminCommonBusinessRuleFields
              :rule="maxResSameStartRule"
              :referenceId="referenceId"
            />
            <hr />
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required|between:1,20"
                  name="Maximum Reservations With Same Start Date"
                  id="ruleLength"
                  placeholder="Enter maximum reservations with same start date"
                  v-model="maxResSameStartRule.length"
                />
                <small class="form-text text-muted mb-2">
                  Maximum number of reservations allowed to have the same start
                  date.
                </small>
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-3">
              Submit
            </button>
            <button
              class="btn btn-cancel btn-lg mt-3 ml-1"
              @click="addingRule = false"
              type="button"
            >
              Cancel
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <AdminBusinessRuleTable
      :tableData="tableData"
      @viewRule="viewRule"
      @editRule="editRule"
      :uniqueFields="uniqueFields"
    />
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AdminCommonBusinessRuleFields from "@/components/admin/AdminCommonBusinessRuleFields";
import AdminBusinessRuleTable from "@/components/admin/AdminBusinessRuleTable";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminBusinessRuleService from "@/services/admin/AdminBusinessRuleService.js";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminMaximumReservationsSameStartDate",
  title: "Admin - Maximum Reservations Same Start Date",
  components: {
    ValidationObserver,
    AdminCommonBusinessRuleFields,
    AdminBusinessRuleTable,
    FormErrorAlert,
    TextInput
  },
  data() {
    return {
      maxResSameStartRule: {
        name: "Maximum Reservations With Same Start Date",
        isActive: true,
        productId: "",
        productTypeId: "",
        areaId: "",
        loopId: "",
        seasonTypeId: "",
        salesChannelId: "",
        paymentTypeId: ""
      },
      tableData: [],
      baseOptions: [{ id: "", name: "All" }],
      errors: [],
      uniqueFields: [
        {
          key: "length",
          sortable: true,
          label: "Max Reservations With Same Start Date"
        }
      ],
      addingRule: false
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    referenceId() {
      return `G${(this.tableData.length + 1 + "").padStart(3, "0")}`;
    }
  },
  methods: {
    async initialize() {
      this.loadData();
    },
    async loadData() {
      const businessRuleService = new AdminBusinessRuleService(this.tenantId);
      businessRuleService.getAllMaxResSameStart().then(response => {
        this.tableData = response.data.map((x, index) => {
          return { ...x, referenceId: `G${(index + 1 + "").padStart(3, "0")}` };
        });
      });
    },
    async onSubmit() {
      this.$refs.maxResSameStartForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.maxResSameStartForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.maxResSameStartForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          //Map to request object
          var request = {
            isActive: this.maxResSameStartRule.isActive,
            productId: this.maxResSameStartRule.productId,
            productTypeId: this.maxResSameStartRule.productTypeId,
            areaId: this.maxResSameStartRule.areaId,
            loopId: this.maxResSameStartRule.loopId,
            salesChannelId: this.maxResSameStartRule.salesChannelId,
            seasonTypeId: this.maxResSameStartRule.seasonTypeId,
            paymentTypeId: this.maxResSameStartRule.seasonTypeId,
            comments: this.maxResSameStartRule.comments,
            startDate: this.maxResSameStartRule.startDate,
            endDate: this.maxResSameStartRule.endDate,
            length: this.maxResSameStartRule.length,
            validationMessage: this.maxResSameStartRule.validationMessage
          };

          if (this.maxResSameStartRule.productClasses) {
            request.productClassIds = this.maxResSameStartRule.productClasses.map(
              n => n.id
            );
          }
          const businessRuleService = new AdminBusinessRuleService(
            this.tenantId
          );
          var response = await businessRuleService.addMaxReservationsWithSameStartDate(
            request
          );
          if (response.statusCode == "Success") {
            //Reload data for grid
            this.loadData();
            this.maxResSameStartRule = {
              name: "Maximum Reservations With Same Start Date",
              isActive: true,
              productId: "",
              productTypeId: "",
              areaId: "",
              loopId: "",
              seasonTypeId: "",
              salesChannelId: "",
              paymentTypeId: ""
            };
            this.addingRule = false;
            window.scrollTo(0, 0);
          }
        }
      });
    },
    viewRule(item) {
      this.$router
        .push(
          `/admin/brm/maximum-reservations-same-start-date/${item.id}?refId=${item.referenceId}&v=true`
        )
        .catch(() => {});
    },
    editRule(item) {
      this.$router
        .push(
          `/admin/brm/maximum-reservations-same-start-date/${item.id}?refId=${item.referenceId}&v=false`
        )
        .catch(() => {});
    }
  },
  created() {
    this.initialize();
  }
};
</script>
